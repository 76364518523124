module.exports = [{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"unifistudio","accessToken":"MC5YcE9LMlJBQUFPN0cyOUNf.77-9SQHvv70a77-977-977-977-977-9JWHvv71kD--_ve-_ve-_ve-_vULvv70aAx_vv71QJnxiT13vv70","path":"/preview","previews":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Red Hat Display:400,500,700,900","Open Sans:300,400,600"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
