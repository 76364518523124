import React from 'react';

import { StylesProvider } from '@nelnet/unifi-components-react';

import { hasEntries } from './utils/hasEntries';
import stylesProviderProps from './.cache/styles-provider-props';

import ThemeProvider from './src/components/layout/ThemeProvider';

// Prismic linkResolver
import { registerLinkResolver } from '@prismicio/gatsby-source-prismic-graphql';
import linkResolver from './utils/linkResolver';
registerLinkResolver(linkResolver);

export const onInitialClientRender = () => {
  if (process.env.BUILD_STAGE === `develop`) {
    return;
  }

  // Remove the server-side injected CSS.
  const jssStyles = document.querySelector(`#jss-server-side`);
  if (jssStyles) {
    jssStyles.parentNode.removeChild(jssStyles);
  }
};

export const wrapRootElement = ({ element }) => {
  const stylesProvider = hasEntries(stylesProviderProps) ? stylesProviderProps : null;

  if (!stylesProvider) {
    return <ThemeProvider>{element}</ThemeProvider>;
  }

  return (
    <StylesProvider {...stylesProvider}>
      <ThemeProvider>{element}</ThemeProvider>
    </StylesProvider>
  );
};
