import * as React from 'react';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { createMuiTheme } from '@material-ui/core/styles';

import {
  createTheme,
  CssBaseline,
  UnifiThemeProvider,
  ThemeOptions,
} from '@nelnet/unifi-components-react';

import { materialTheme } from '@nelnet/unifi-components-react/dist/styles/themes';

const colorPrimary = '#AED136';
const colorSecondary = '#0E729A';
const colorSecondaryLight = '#11AECF';
const colorExtendedMagenta = '#CD2F79';
const colorExtendedPurple = '#6867AF';
const colorGreyDark = '#565B64';
const colorGreyLight = '#EDEEEF';
const colorInfo = colorSecondaryLight;
const colorError = colorPrimary;
const colorBackgroundGrey = '#FAFAFA';

const globalLayoutStyles = {
  // strongly recommended
  body: {
    minHeight: '100vh',
  },
  a: {
    color: colorSecondary,
  },
  // Optional - demos how to affect the root wrapper
  '#___gatsby': {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
};
const parentTheme = createMuiTheme(materialTheme);

const extendedUnifi = {
  themeName: 'Unifi Design Studio',
  palette: {
    primary: {
      main: colorPrimary,
    },
    secondary: {
      main: colorSecondary,
      light: colorSecondaryLight,
    },
    extended: {
      magenta: colorExtendedMagenta,
      purple: colorExtendedPurple,
    },
    neutral: {
      main: colorExtendedMagenta,
      dark: colorExtendedPurple,
    },
    grey: {
      200: colorGreyLight,
      700: colorGreyDark,
    },
    info: {
      main: colorInfo,
    },
    error: {
      main: colorError,
    },
    background: {
      main: colorBackgroundGrey,
    },
  },
  typography: {
    fontFamily: ['Red Hat Display', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.8125rem',
      lineHeight: '1',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      fontWeight: 700,
      //h1 takes on the appearance of h2 element
      '&.small': {
        fontSize: '1.125rem',
        lineHeight: '1.25',
        letterSpacing: '2.5px',
        textTransform: 'uppercase',
        fontWeight: 700,
      },
    },
    h2: {
      fontSize: '1.125rem',
      lineHeight: '1.25',
      letterSpacing: '2.5px',
      textTransform: 'uppercase',
      fontWeight: 700,
      padding: '0.5rem',
      //h2 takes on the appearance of h4 element
      '&.lowercase': {
        fontSize: '2.1875rem',
        lineHeight: '1.25',
        letterSpacing: '0',
        textTransform: 'none',
        fontWeight: 500,
        paddingLeft: 0,
      },
    },
    h3: {
      fontSize: '0.75rem',
      lineHeight: '0.9375',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      //h3 takes on the appearance of h4 element
      '&.lowercase': {
        fontSize: '2.1875rem',
        lineHeight: '1.25',
        letterSpacing: '0',
        textTransform: 'none',
        fontWeight: 500,
      },
      //h3 takes on the appearance of h5 element
      '&.regular': {
        fontSize: '1.5625rem',
        lineHeight: '1.875',
        letterSpacing: '0',
        textTransform: 'none',
      },
    },
    h4: {
      fontSize: '2.1875rem',
      lineHeight: '1.25',
      letterSpacing: '0',
      fontWeight: 500,
      //h4 takes on the appearance of h5 element
      '&.regular': {
        fontSize: '1.5625rem',
        lineHeight: '1',
        letterSpacing: '0',
        textTransform: 'none',
        fontWeight: 400,
      },
      //h4 takes on the appearance of h2 element
      '&.small': {
        fontSize: '1.125rem',
        letterSpacing: '2.5px',
        textTransform: 'uppercase',
        fontWeight: 700,
        padding: '0.5rem',
      },
      //h4 takes on the appearance of bolded body element
      '&.bold': {
        fontSize: '1rem',
        lineHeight: '1.25',
        letterSpacing: '0',
        fontWeight: 700,
      },
    },
    h5: {
      fontSize: '1.5625rem',
      lineHeight: '1',
      letterSpacing: '0',
      textTransform: 'none',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.25',
      letterSpacing: '0',
      //body takes on appearance of h1 element
      '&.extra-large': {
        fontSize: '2.8125rem',
        lineHeight: '1',
        letterSpacing: '2px',
        textTransform: 'uppercase',
        fontWeight: 700,
      },
      //body takes on {similar} appearance of h5 element
      '&.small': {
        fontSize: '1.5625rem',
        letterSpacing: '0',
      },
      //body takes on the appearance of large h5 element
      '&.large': {
        fontSize: '2.1875rem',
        lineHeight: '1',
        letterSpacing: '0',
        fontWeight: 500,
      },
    },
    button: {
      fontSize: '0.8125rem',
      fontWeight: 700,
    },
    '@global': {
      a: {
        color: '#0E729A',
      },
    },
  },
  overrides: {
    MuiAppBar: {
      positionSticky: {
        zIndex: 2,
      },
    },
    MuiCssBaseline: {
      '@global': { ...globalLayoutStyles },
    },
    MuiButton: {
      root: {
        borderRadius: '0',
        padding: '10px 20px',
        margin: '1rem 0 0',
      },
      containedPrimary: {
        margin: '2rem 0 auto',
        '&:focus': {
          boxShadow: '0 0 0 3px rgb(0, 0, 0, 0.87)',
        },
      },
      outlinedPrimary: {
        borderColor: colorSecondary,
        color: '#000000',
        padding: '10px 20px',
        '&:hover': {
          backgroundColor: `${lighten(colorSecondary, 0.9)}`,
          borderColor: colorSecondary,
        },
        '&:focus': {
          borderColor: 'rgb(0, 0, 0, 0.87)',
        },
      },
      textPrimary: {
        color: '#000000',
        padding: '10px 20px',
        '&:hover': {
          backgroundColor: `${lighten(colorSecondary, 0.9)}`,
          borderColor: colorSecondary,
        },
        '&:focus': {
          borderColor: 'rgb(0, 0, 0, 0.87)',
          boxShadow: '0 0 0 3px rgb(0, 0, 0, 0.87)',
        },
      },
    },
    MuiCard: {
      root: {
        padding: '3rem 1rem 0',
        borderRadius: 0,
      },
    },
    MuiCardContent: {
      root: {
        maxWidth: '80rem',
        margin: 'auto',
      },
    },
    MuiCardHeader: {
      title: {
        textAlign: 'left',
      },
      subheader: {
        textAlign: 'left',
      },
    },
    MuiDivider: {
      root: {
        height: '3px',
      },
    },
    MuiLink: {
      root: {
        fontFamily: 'Red Hat Display',
        color: colorSecondary,
      },
      underlineAlways: {
        padding: '0.5rem 0.25rem',
      },
    },
    MuiList: {
      root: {
        backgroundColor: colorBackgroundGrey,
      },
    },
    MuiListItemText: {
      secondary: {
        color: '#000000',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
        borderRadius: 0,
      },
    },
    UnifiDataListItem: {
      datalistKey: {
        textAlign: 'center',
        marginRight: '2rem',
        textTransform: 'none',
        width: '100%',
        [parentTheme.breakpoints.up('sm')]: {
          width: '15%',
          textAlign: 'left',
        },
      },
      datalistValue: {
        width: '100%',
        [parentTheme.breakpoints.up('sm')]: {
          width: '60%',
        },
      },
      datalistHorizontal: {
        flexWrap: 'wrap',
        [parentTheme.breakpoints.up('sm')]: {
          flexWrap: 'nowrap',
        },
        '& >*:last-child': {
          textAlign: 'left',
          fontWeight: 500,
        },
      },
    },
    UnifiPanelContent: {
      root: {
        padding: '0 4.5rem 0',
        '& a': {
          display: 'list-item',
          textAlign: 'left',
          listItemStyle: '',
          '&::marker': {
            fontSize: '12px',
          },
        },
      },
    },
    UnifiPanelHeader: {
      collapseIcon: {
        marginRight: '1rem',
        marginLeft: 0,
      },
      icon: {
        float: 'left',
      },
      title: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: '1.25',
        letterSpacing: '0',
        textAlign: 'left',
        textTransform: 'none',
      },
      titleCollapse: {
        '& button': {
          '& span': {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-end',
            width: '100%',
          },
        },
      },
    },
  },
} as ThemeOptions;

// Used by gatsby-browser and gatsby-ssr to correctly apply/update stylesheets
const ThemeProvider: React.FunctionComponent = ({ children }) => {
  const theme = createTheme(extendedUnifi);

  return (
    <UnifiThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {children}
    </UnifiThemeProvider>
  );
};

export default ThemeProvider;
