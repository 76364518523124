// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-prismicio-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../../../node_modules/@prismicio/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-prismicio-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-capabilities-tsx": () => import("./../../../src/pages/capabilities.tsx" /* webpackChunkName: "component---src-pages-capabilities-tsx" */),
  "component---src-pages-connect-tsx": () => import("./../../../src/pages/connect.tsx" /* webpackChunkName: "component---src-pages-connect-tsx" */),
  "component---src-pages-guidelines-tsx": () => import("./../../../src/pages/guidelines.tsx" /* webpackChunkName: "component---src-pages-guidelines-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kitchen-sink-tsx": () => import("./../../../src/pages/kitchen-sink.tsx" /* webpackChunkName: "component---src-pages-kitchen-sink-tsx" */),
  "component---src-templates-capabilities-details-template-tsx": () => import("./../../../src/templates/CapabilitiesDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-capabilities-details-template-tsx" */),
  "component---src-templates-guidelines-details-template-tsx": () => import("./../../../src/templates/GuidelinesDetailsTemplate.tsx" /* webpackChunkName: "component---src-templates-guidelines-details-template-tsx" */),
  "component---src-templates-personas-template-tsx": () => import("./../../../src/templates/PersonasTemplate.tsx" /* webpackChunkName: "component---src-templates-personas-template-tsx" */),
  "component---src-templates-primary-template-tsx": () => import("./../../../src/templates/PrimaryTemplate.tsx" /* webpackChunkName: "component---src-templates-primary-template-tsx" */)
}

